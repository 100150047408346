import React from "react";
import {
  useAllPressReleasesData,
  useAllBlogPostsData,
  useDefaultBlogImageData,
} from "@staticQueries";
import { DefaultLayout } from "@layout";
import { Section, Container, Text, RichText, Image } from "@base";
import { VideoPlayer } from "@atoms";
import { BackLink, Share } from "@molecules";
import { PageNavigation, ContentBuilder, LatestNews } from "@organisms";

/* eslint react/prop-types: 0 */
const PressRelease = ({ data, ...pageProps }) => {
  const {
    uid,
    heading,
    subheading,
    date,
    location,
    image,
    copy,
    meta,
    showDate,
    showLocation,
    videoUrl,
    body,
    url,
    excerpt,
    author,
    featured,
  } = data;
  const allReleases = useAllPressReleasesData();
  const sortedReleases = allReleases.sort(
    (a, b) => new Date(b.datetime || b.date) - new Date(a.datetime || a.date)
  );

  const allBlogPosts = useAllBlogPostsData();
  const sortedBlogPosts = allBlogPosts.sort(
    (a, b) => new Date(b.datetime || b.date) - new Date(a.datetime || a.date)
  );

  const { defaultImage } = useDefaultBlogImageData();

  return (
    <DefaultLayout {...pageProps} meta={meta}>
      <div className="bg-black h-20 w-full" />
      {/* <Hero variant="small" image={image} /> */}
      {/* <SliceFullBleedImage
        textY="bottom"
        textX="center"
        size="article"
      /> */}

      {/* Intro */}
      <Section
        hasPaddingTop={false}
        hasPaddingBottom={false}
        className="bg-white"
      >
        <Container
          size="article"
          className="flex items-start justify-start my-6"
        >
          <BackLink
            url="/news/"
            text="All Articles"
            className="text-black font-semibold"
          />
        </Container>

        <Container size="article">
          {showDate && (
            <Text
              tag="p"
              options={{ size: "text-base", style: "font-bold" }}
              className="text-blue mt-12 mb-6 uppercase tracking-wider"
            >
              {date}
            </Text>
          )}

          <Text
            tag="h1"
            use="span"
            className="text-black max-w-md leading-1.2 font-sans mb-8 text-xl md:text-2xl lg:text-3xl"
          >
            {heading}
          </Text>

          {author !== null && (
            <div className="flex items-center my-8">
              {author?.headshot && (
                <div className="rounded-full w-16 mr-6 overflow-hidden">
                  <Image {...author?.headshot} aspectratio="1:1" />
                </div>
              )}
              <div className="text-gray-2 flex flex-col">
                <Text tag="span" use="p">
                  {author?.name}
                </Text>
                <Text tag="span" use="p">
                  {author?.company
                    ? `${author?.title}, ${author?.company}`
                    : author?.title}
                </Text>
              </div>
            </div>
          )}

          {excerpt && (
            <div className="w-full max-w-md">
              <Text tag="p" options={{ size: "text-lg md:text-xl" }}>
                {excerpt}
              </Text>
            </div>
          )}

          <Share url={url} />

          <div>
            <Image {...(image || defaultImage)} aspectratio="8:5" />
          </div>

          {!!videoUrl?.length && (
            <div>
              <VideoPlayer url={videoUrl} />
            </div>
          )}

          <Text tag="p" className="text-gray-2 mt-6 mb-12">
            {subheading}
          </Text>
        </Container>

        {copy.html.length > 0 && copy.text !== "" && (
          <Container size="article" className="mt-6">
            <RichText
              html={copy.html}
              addBeforeFirst={
                showDate || showLocation
                  ? {
                      searchTag: "p",
                      tag: "strong",
                      text: location
                        ? `${location}, ${date} \u2014`
                        : `${date} \u2014`,
                    }
                  : false
              }
            />
          </Container>
        )}

        {body.length > 0 && <ContentBuilder blocks={body} />}

        <Container className="flex justify-center md:justify-end mt-8 md:mt-12 lg:mt-16 mb-12">
          <PageNavigation
            items={url.includes("/blog/") ? sortedBlogPosts : sortedReleases}
            uid={uid}
            nextText="Next Article"
            prevText="Last Article"
          />
        </Container>

        {featured?.length > 0 && (
          <div className="bg-gray-0">
            <LatestNews heading="Related Articles" articles={featured} blog />
          </div>
        )}
      </Section>
    </DefaultLayout>
  );
};

export default PressRelease;
